/* global BACKEND_URL */

import axios from "axios";
import _ from "lodash";
import React, { Component } from "react";
import DateTime, { moment } from "react-datetime";
import Autocomplete from "react-google-autocomplete";
import MouseFollower from "../MouseFollower/MouseFollower";
import TextField from "../FormFields/TextField";
import RadioGroup from "../FormFields/RadioGroup";
import CheckboxGroup from "../FormFields/CheckboxGroup";
import UploadField from "../FormFields/UploadField";
import Accordion from "../FormFields/Accordion";
import ArtistScheduling from "./ArtistScheduling";
import formatter from "../../helper/formatters";
import PageHeader from "../Global/PageHeader";
import masks from "../../helper/masks";
import stringHelper from "../../helper/string";
import CommentComponent from "../Schedules/Comments/addComment";
import { addCommentInArtist } from "../../actions/artists/add_comment_artist";
import NotificationSystem from "react-notification-system";
import { Link } from "react-router";
import { fetchAreas } from "../../actions/areas/fetch_areas";
import { fetchPolygonsByAreaId } from "../../actions/dynamic_prices/fetch_polygons_by_area";
import { Map, GoogleApiWrapper } from "google-maps-react";
import { Polygon } from "google-maps-react/dist/components/Polygon";
import "moment/locale/pt-br";

// styles
import "./../../style/form.css";
import SelectField from "../FormFields/SelectField";
import Swal from "sweetalert2";
import { Toast } from '../../helper/alerts';
import { connect } from "react-redux";
import { fetchCategoriesServices } from "../../actions/service/fetch_service";

const birthdateFormat = "DD/MM/YYYY";

const genders = [
  {
    label: "Feminino",
    value: "F",
  },
  {
    label: "Masculino",
    value: "M",
  },
];

const yesAndNo = [
  {
    label: "Sim",
    value: "true",
  },
  {
    label: "Não",
    value: "false",
  },
];

const optionsList = [
  { value: "Não enviou o documento", text: "Não enviou o documento" },
  {
    value: "Não atende há mais de 6 meses",
    text: "Não atende há mais de 6 meses",
  },
  { value: "Biossegurança", text: "Biossegurança - Falta de EPI" },
  { value: "Atingiu 31 pontos", text: "Atingiu 31 pontos" },
  { value: "Desintermediação", text: "Desintermediação" },
  { value: "Não comparecimento", text: "Não comparecimento" },
  {
    value: "3 primeiras avaliações negativas",
    text: "3 primeiras avaliações negativas",
  },
  {
    value: "Desistência do pedido com menos de 15 minutos",
    text: "Desistência do pedido com menos de 15 minutos",
  },
  { value: "Fraude", text: "Fraude" },
  { value: "Verificacao Social", text: "Verificação Social" },
  { value: "Desempenho Qualidade", text: "Desempenho Qualidade" },
];

const statusList = [
  { value: "ativo", text: "Ativo", isActive: true },
  { value: "desligado", text: "Desligada da Singu", isActive: false },
  { value: "desabilitado", text: "Desabilitado", isActive: false },
  { value: "churn", text: "Churn", isActive: true },
  { value: "desistiu", text: "Desistiu da Singu", isActive: false },
];

const unmaskNumber = (text) => text.replace(/[^0-9]/g, "");

class ArtistForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addressArrived: false,
      editing: false,
      partnerId: "",
      comments: [],
      polygons: [],
      selectedPolygons: [],
      areas: [],
      isAllocation: "false",
      availability: [],
      categories: [],
      categoriesLoading: false,
      polygonInfo: {
        show: false,
        name: "",
      }
    };

    this.handleShedules = this.handleShedules.bind(this);
  }

  validDate(current) {
    return current.isAfter(moment().subtract(1, "day"));
  }

  componentDidMount() {
    const { lead, artist, partner } = this.props;

    if (lead) {
      this.handleLeadMigration(lead);
    } else if (artist) {
      this.setState({
        editing: true,
        comments: artist.comments || [],
        partnerId: artist._id,
      });
      this.handleArtistEdit(artist);
    } else if (partner) {
      this.handlePartnerMigration(partner);
    }

    this.loadServices();
    this.loadAreas();
  }

  componentWillUnmount() {
    const { lead, artist, partner } = this.props;

    if (lead) {
      this.props.resetLead();
    } else if (artist) {
      this.props.resetArtist();
    } else if (partner) {
      this.props.resetPartner();
    }
  }

  componentWillReceiveProps(props) {
    const { lead, artist, partner } = props;

    if (lead) {
      this.props.resetLead();
    } else if (artist) {
      this.props.resetArtist();
    } else if (partner) {
      this.props.resetPartner();
    }
  }

  getSignedUploadUrl(uploadType, partnerDoc, filename, filetype) {
    let type = filetype;
    if (!filetype) {
      const fileNameSplited = filename.split(".");
      type = fileNameSplited[fileNameSplited.length - 1];
    }
    return axios.get(
      `${BACKEND_URL}/v2/bo/partners/${uploadType}/s3/signed-url`,
      {
        params: { partnerDoc, filename, filetype: type },
      }
    );
  }

  formatArtistData(values) {
    const { selectedPolygons, availability } = this.state;
    let partner = {
      email: values.email.trim().toLowerCase(),
      name: stringHelper.capitalizeAll(values.name.trim(), " "),
      phone: formatter.assemblePhoneObject(values.phoneNumber),
      address: {
        place: values.place,
        streetNumber: values.streetNumber,
        complement: values.complement,
        neighbor: values.neighbor,
        city: values.city,
        uf: values.uf,
        location: values.location,
        zipCode: values.zipcode.replace(/\-/, ""),
      },
      cpf: {
        documentNumber: unmaskNumber(values.cpf),
        image: values.cpfUpload && values.cpfUpload.fileUrl,
      },
      rg: {
        documentNumber: unmaskNumber(values.rg),
        image: values.rgUpload && values.rgUpload.fileUrl,
      },
      birthdate: values.birthdate,
      gender: values.gender,
      indicationCode: values.indicationCode || "",
      active: values.status.isActive,
      status: values.status.value,
      deactivationReason: !values.status.isActive
        ? values.deactivationReason
        : "",
      singuPayment: values.singuPayment === "true",
      realtime: values.realtime === "true",
      beginner: values.beginner === "true",
      novice: values.novice === "true",
      disabledUntil: this.state.disabledUntil || values.disabledUntil,
      nacionality: values.nacionality,
      naturality: values.naturality,
      dataexpedition: values.dataexpedition,
      entityemission: values.entityemission,
      fatherName: values.fatherName,
      motherName: values.motherName,
      profilePicture: values.profilePicture && values.profilePicture.fileUrl,
      enabledCities: values.enabledCities,
      enabledServices: values.enabledServices,
      totalRating: values.totalRating,
      totalReviewSched: values.totalReviewSched,
      moipCredentials: values.moipCredentials,
      password: values.password,
      leadId: values.leadId,
      partnerId: values.partnerId,
      polygons: selectedPolygons,
      availability: availability,
    };

    if (
      values.naturaID ||
      values.naturaIsActive ||
      values.naturaStatus ||
      values.naturaDigitalSpaceName
    ) {
      Object.assign(partner, { naturaConsultant: {} });
      if (values.naturaID)
        Object.assign(partner.naturaConsultant, { id: values.naturaID });
      if (values.naturaIsActive)
        Object.assign(partner.naturaConsultant, {
          isActive: values.naturaIsActive === "true",
        });
      if (values.naturaStatus)
        Object.assign(partner.naturaConsultant, {
          status: values.naturaStatus,
        });
      if (values.naturaDigitalSpaceName)
        Object.assign(partner.naturaConsultant, {
          digitalSpaceName: values.naturaDigitalSpaceName,
        });
    }

    const takeRateValue = parseInt(values.takeRateValue);

    if (takeRateValue >= 0 && takeRateValue <= 100) {
      let expiration;
      if (values.takeRateIncreaseValue && values.takeRateIncreaseForEachDays) {
        let days =
          ((40 - takeRateValue) / values.takeRateIncreaseValue) *
          values.takeRateIncreaseForEachDays;
        expiration = moment().add(days, "days");
      } else {
        expiration = moment(this.state.expiration);
      }

      partner.takeRate = {
        value: takeRateValue,
        increaseValue: parseInt(values.takeRateIncreaseValue) || 0,
        increaseForEachDays: parseInt(values.takeRateIncreaseForEachDays) || 0,
      };

      if (expiration && expiration.isValid() && expiration.isAfter(moment())) {
        partner.takeRate.expiration = expiration.utc().format();
      } else {
        partner.takeRate.expiration = moment().add(7, "days").utc().format();
      }
    }

    return partner;
  }

  notify(message, level) {
    const title = level === "success" ? "Sucesso" : "Erro";
    this.refs.notificationSystem.addNotification({
      title,
      message,
      level,
      position: "tc",
    });
  }

  onSubmit(evt) {
    let submitPromise;
    const {
      notify,
      handleSubmit,
      valid,
      errors,
      newArtist,
      updateArtist,
      updateParametesArtist,
      touchAll,
      values,
    } = this.props;
    const { artist, lead } = this.state;
    const fulfill = (action) => {
      if (action.error) {
        if (action.type == "UPDATE_PARAMETES_ARTIST") {
          Swal.fire({
            icon: "warning",
            title: "Falha ao atualizar alocação de pedidos",
            html: "- Atualização do especialista realizada com sucesso! <br>- Erro na atualização da alocação: <strong>" + action.payload.response.data.message + "</strong>",
          });
        } else if (action.payload.data) {
          const error = action.payload.data.error;
          notify(
            error ? error.description : action.payload.statusText,
            "error"
          );
        } else {
          notify(action.payload.message, "error");
        }
      } else {
        if (lead) {
          this.props.router.push("leads");
        } else if (artist) {
          Toast(2000).fire({
            icon: 'success',
            title: 'Especialista atualizado com sucesso!',
          });
          this.props.router.push(`artist/${this.props.params.artistId}`);
        } else {
          this.props.router.push(`artists`);
        }
      }
    };

    evt.preventDefault();

    const status = statusList.find((status) => status.value === values.status);
    if (!status) return Swal.fire({ icon: "error", title: "Status inválido" });
    Object.assign(values, { status });

    if (!status.isActive && !values.deactivationReason) {
      return Swal.fire({
        icon: "warning",
        title: "Campo Obrigatório",
        text: "O campo de motivo de desativação é obrigatório quando a especialista for desativada",
      });
    }

    if (valid) {
      const formattedData = this.formatArtistData(values);

      if (artist) {
        const { selectedPolygons, availability, isAllocation } = this.state;
        const isAllocationBoolean = isAllocation === "false" ? false : true;
        submitPromise = handleSubmit(() => updateArtist(artist._id, formattedData))(evt)
          .then(({ payload }) => {
            const { polygons } = payload.data;
            return updateParametesArtist(artist._id, availability, polygons, isAllocationBoolean);
          });
      } else {
        submitPromise = handleSubmit(() => newArtist(formattedData))(evt);
      }

      if (submitPromise) {
        submitPromise.then(fulfill);
      }
    } else {
      touchAll();
      const name = _.keys(errors)[0];
      const input = $(`[id="${name}Wrapper"]`).eq(0);
      const offset = input[0] ? input.offset().top : 0;
      console.log(input);

      $("html, body").animate({ scrollTop: offset }, 400);
    }
  }

  servicesIsInvalid() {
    const {
      fields: { enabledServices },
    } = this.props;
    const { value, valid, touched } = enabledServices;
    return value && !value.length > 0 && !valid && touched;
  }

  servicesWrapperClass() {
    const {
      fields: { enabledServices },
    } = this.props;
    const { valid } = enabledServices;
    const invalidClass = this.servicesIsInvalid() ? "is-error" : "";
    const validClass = valid ? "is-valid" : "";
    return `${invalidClass} ${validClass}`;
  }

  renderServiceErrorMessage() {
    const {
      fields: { enabledServices },
    } = this.props;
    if (this.servicesIsInvalid()) {
      return <span className="Form-error">{enabledServices.error}</span>;
    }
    return null;
  }

  handleArtistEdit(artist) {
    const {
      name,
      email,
      indicationCode,
      phoneNumber,
      cpf,
      rg,
      birthdate,
      gender,
      beginner,
      novice,
      singuPayment,
      realtime,
      active,
      deactivationReason,
      disabledUntil,
      artistId,
      rgUpload,
      cpfUpload,
      status,
      profilePicture,
      enabledCities,
      enabledServices,
      nacionality,
      naturality,
      dataexpedition,
      entityemission,
      fatherName,
      motherName,
      takeRateValue,
      takeRateIncreaseValue,
      takeRateIncreaseForEachDays,
      naturaID,
      naturaIsActive,
      naturaStatus,
      naturaDigitalSpaceName,
    } = this.props.fields;

    this.handleAddressInfo(artist.address);
    this.handleShedules(artist?.parametersFilterOrders?.availability || this.state.availability);

    this.setState({
      isAllocation: artist?.parametersFilterOrders ? String(artist?.parametersFilterOrders.isAvailableForOrderAllocation) : this.state.isAllocation
    })

    this.setState({
      selectedPolygons: artist?.parametersFilterOrders ? artist?.parametersFilterOrders.polygons : this.state.selectedPolygons
    })

    if (artist.takeRate) {
      const expirationDate = moment(artist.takeRate.expiration);
      if (expirationDate.isValid() && expirationDate.isAfter(moment())) {
        takeRateValue.autofill(artist.takeRate.value);
        takeRateIncreaseValue.autofill(artist.takeRate.increaseValue || 0);
        takeRateIncreaseForEachDays.autofill(
          artist.takeRate.increaseForEachDays || 0
        );
        this.setState({ expiration: expirationDate });
      }
    }
    name.autofill(artist.name);
    email.autofill(artist.email);
    if (artist.phone && artist.phone.number) {
      const { areaCode, number } = artist.phone;
      phoneNumber.autofill(
        formatter.formatMaskedValue(`${areaCode}${number}`, masks.phone[0])
      );
    } else {
      phoneNumber.autofill(
        formatter.formatMaskedValue(artist.phone, masks.phone[0])
      );
    }

    cpf.autofill(
      formatter.formatMaskedValue(artist.cpf.documentNumber, masks.cpf)
    );

    rg.autofill(
      formatter.formatMaskedValue(artist.rg.documentNumber, masks.rg)
    );

    gender.autofill(artist.gender);

    beginner.autofill(artist.beginner ? artist.beginner.toString() : "false");
    novice.autofill(artist.novice ? artist.novice.toString() : "false");
    singuPayment.autofill(
      artist.singuPayment ? artist.singuPayment.toString() : "false"
    );
    realtime.autofill(artist.realtime ? artist.realtime.toString() : "false");
    active.autofill(artist.active ? artist.active.toString() : "false");
    deactivationReason &&
      deactivationReason.autofill(artist.deactivationReason);
    status.autofill(artist.status);

    enabledCities.autofill(artist.enabledCities);
    enabledServices.autofill(artist.enabledServices);
    artistId.autofill(artist.id);

    if (artist.disabledUntil) {
      const disabledUntilDate = moment(artist.disabledUntil);
      if (disabledUntilDate.isValid()) {
        disabledUntil.autofill(artist.disabledUntil);
        this.setState({ disabledUntil: disabledUntilDate });
      }
    }

    nacionality.autofill(artist.nacionality);
    naturality.autofill(artist.naturality);
    dataexpedition.autofill(artist.dataexpedition);
    entityemission.autofill(artist.entityemission);
    fatherName.autofill(artist.fatherName);
    motherName.autofill(artist.motherName);
    indicationCode.autofill(artist.indicationCode || "");

    if (artist.birthdate) {
      birthdate.autofill(
        moment(artist.birthdate, "YYYY-MM-DD").format(birthdateFormat)
      );
    }

    if (artist.rg.image) {
      rgUpload.autofill({ fileUrl: artist.rg.image });
    }

    if (artist.cpf.image) {
      cpfUpload.autofill({ fileUrl: artist.cpf.image });
    }

    if (artist.profilePicture) {
      profilePicture.autofill({ fileUrl: artist.profilePicture });
    }

    const { naturaConsultant } = artist;

    naturaID.autofill(
      naturaConsultant && naturaConsultant.id ? naturaConsultant.id : ""
    );
    naturaStatus.autofill(
      naturaConsultant && naturaConsultant.status ? naturaConsultant.status : ""
    );
    naturaIsActive.autofill(
      naturaConsultant && typeof naturaConsultant.isActive !== "undefined"
        ? naturaConsultant.isActive.toString()
        : ""
    );
    naturaDigitalSpaceName.autofill(
      naturaConsultant && naturaConsultant.digitalSpaceName
        ? naturaConsultant.digitalSpaceName
        : ""
    );

    this.setState({
      artist,
      pageTitle: `Editando ${artist.name}`,
      submitText: "EDITAR ARTISTA",
    });
  }

  handleLeadMigration(lead) {
    const {
      cpf,
      indicationCode,
      name,
      email,
      phoneNumber,
      leadId,
      gender,
      naturaIsActive,
    } = this.props.fields;

    this.handleAddressInfo(lead.address);

    cpf.autofill(formatter.formatMaskedValue(lead.cpf, masks.cpf));
    name.autofill(lead.name);
    email.autofill(lead.email);
    indicationCode.autofill(lead.indicationCode);
    phoneNumber.autofill(formatter.formatPhone(lead.phone));
    leadId.autofill(lead._id);
    gender.autofill(lead.gender || "");
    naturaIsActive.autofill(lead.isNaturaConsultant ? "true" : "false");

    this.setState({
      lead,
      pageTitle: `Transformar em especialista ${lead.name}`,
      submitText: "TRANSFORMAR EM ARTISTA",
    });
  }

  handlePartnerMigration(partner) {
    const {
      partnerId,
      name,
      email,
      phoneNumber,
      cpf,
      rg,
      gender,
      active,
      beginner,
      novice,
      singuPayment,
      status,
      realtime,
      enabledCities,
      enabledServices,
      totalRating,
      totalReviewSched,
      moipCredentials,
      birthdate,
      password,
      nacionality,
      naturality,
      dataexpedition,
      entityemission,
      fatherName,
      motherName,
      disabledUntil,
      takeRateValue,
      takeRateIncreaseValue,
      takeRateIncreaseForEachDays,
    } = this.props.fields;

    this.handleAddressInfo(partner.address);

    takeRateValue.autofill(partner.takeRate ? partner.takeRate.value : "");
    takeRateIncreaseValue.autofill(
      partner.takeRate ? partner.takeRate.takeRateIncreaseValue : 0
    );
    takeRateIncreaseForEachDays.autofill(
      partner.takeRate ? partner.takeRate.takeRateIncreaseForEachDays : 0
    );

    nacionality.autofill(partner.nacionality);
    naturality.autofill(partner.naturality);
    dataexpedition.autofill(partner.dataexpedition);
    entityemission.autofill(partner.entityemission);
    fatherName.autofill(partner.fatherName);
    motherName.autofill(partner.motherName);

    name.autofill(partner.name);
    email.autofill(partner.email);
    phoneNumber.autofill(formatter.formatPhone(partner.phone));
    cpf.autofill(formatter.formatMaskedValue(partner.cpf, masks.cpf));
    rg.autofill(formatter.formatMaskedValue(partner.rg, masks.rg));
    gender.autofill(partner.gender);

    beginner.autofill(partner.beginner ? partner.beginner.toString() : "false");
    novice.autofill(partner.novice ? partner.novice.toString() : "false");
    singuPayment.autofill(
      partner.singuPayment ? partner.singuPayment.toString() : "false"
    );
    realtime.autofill(partner.realtime ? partner.realtime.toString() : "false");
    active.autofill(partner.active ? partner.active.toString() : "false");
    status.autofill(partner.status);

    disabledUntil.autofill(partner.disabledUntil);
    enabledCities.autofill(partner.enabledCities);
    enabledServices.autofill(partner.enabledServices);
    totalRating.autofill(partner.totalRating);
    totalReviewSched.autofill(partner.totalReviewSched);
    moipCredentials.autofill(partner.moipCredentials);
    password.autofill(partner.password);
    partnerId.autofill(partner._id);

    if (partner.birthdate) {
      birthdate.autofill(
        moment(partner.birthdate, "YYYY-MM-DD").format(birthdateFormat)
      );
    }

    this.setState({
      partner,
      pageTitle: `Transformar em especialista ${partner.name}`,
      submitText: "TRANSFORMAR EM ARTISTA",
    });
  }

  handleAddressInfo(address) {
    this.props.fields.place.autofill(address.place || address.streetName);
    this.props.fields.streetNumber.autofill(address.streetNumber);
    this.props.fields.neighbor.autofill(
      address.neighbor || address.neighborhood
    );
    this.props.fields.city.autofill(address.city);
    this.props.fields.uf.autofill(address.uf);
    this.props.fields.zipcode.autofill(
      formatter.formatMaskedValue(
        address.zipCode || address.zipcode,
        masks.zipcode
      )
    );
    this.props.fields.complement.autofill(address.complement);
    this.props.fields.location &&
      this.props.fields.location.autofill(address.location || {});
    this.setState({ addressArrived: true });
  }

  handleShedules(availability) {
    this.setState({
      availability: availability
    })
  }

  populateAddressInfo(address) {
    let lat,
      lng,
      place,
      streetNumber,
      neighbor,
      city,
      uf,
      zipCode,
      complement = "";

    if (address && address.geometry && address.geometry.location) {
      lat =
        (address.geometry.location.lat && address.geometry.location.lat()) || 0;
      lng =
        (address.geometry.location.lng && address.geometry.location.lng()) || 0;
    }

    address.address_components.forEach((loc) => {
      if (loc.types.indexOf("street_number") !== -1) {
        streetNumber = loc.long_name;
      } else if (
        loc.types.indexOf("route") !== -1 ||
        loc.types.indexOf("street_address") !== -1
      ) {
        place = loc.long_name;
      } else if (
        loc.types.indexOf("sublocality_level_1") !== -1 ||
        loc.types.indexOf("sublocality") !== -1 ||
        loc.types.indexOf("neighborhood") !== -1
      ) {
        neighbor = loc.long_name;
      } else if (
        loc.types.indexOf("administrative_area_level_2") !== -1 ||
        loc.types.indexOf("locality") !== -1
      ) {
        city = loc.long_name;
      } else if (loc.types.indexOf("administrative_area_level_1") !== -1) {
        uf = loc.short_name;
      } else if (loc.types.indexOf("postal_code") !== -1) {
        zipCode = loc.long_name;
      }
    });

    this.handleAddressInfo({
      location: {
        type: "Point",
        coordinates: [lng, lat],
      },
      place,
      streetNumber,
      neighbor,
      city,
      uf,
      zipCode,
      complement,
    });
  }

  uploadRgCallback(file, onProgress) {
    const { uploadPartnerImage } = this.props;
    const fulfill = ({ data }) => uploadPartnerImage(data, file, onProgress);
    const docNumber = this.props.params.artistId;

    return this.getSignedUploadUrl(
      "documents",
      docNumber,
      "rg.png",
      file.type
    ).then(fulfill);
  }

  uploadCpfCallback(file, onProgress) {
    const { uploadPartnerImage } = this.props;
    const fulfill = ({ data }) => uploadPartnerImage(data, file, onProgress);
    const docNumber = this.props.params.artistId;

    return this.getSignedUploadUrl(
      "documents",
      docNumber,
      "cpf.png",
      file.type
    ).then(fulfill);
  }

  uploadPictureCallback(file, onProgress) {
    const { uploadPartnerImage } = this.props;
    const fulfill = ({ data }) => uploadPartnerImage(data, file, onProgress);
    const docNumber = this.props.params.artistId;
    const splitedName = file.name.split(".");
    return this.getSignedUploadUrl(
      "pictures",
      docNumber,
      `profile.${splitedName[splitedName.length - 1]}`,
      splitedName[splitedName.length - 1]
    ).then(fulfill);
  }

  renderAddressFields() {
    const { addressArrived } = this.state;

    const {
      place,
      streetNumber,
      complement,
      neighbor,
      city,
      uf,
      zipcode,
      location,
    } = this.props.fields;

    if (addressArrived) {
      return (
        <div>
          <div className="row">
            <div className="col-sm-3">
              <TextField
                name="place"
                label="Rua *"
                inputData={place}
                placeholder="Insira aqui a rua"
              />
            </div>
            <div className="col-sm-3">
              <TextField
                name="streetNumber"
                label="Número *"
                inputData={streetNumber}
                placeholder="Insira aqui o número"
              />
            </div>
            <div className="col-sm-6">
              <TextField
                name="complement"
                label="Complemento"
                inputData={complement}
                placeholder="Insira aqui o complemento"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-3">
              <TextField
                name="neighbor"
                label="Bairro *"
                inputData={neighbor}
                placeholder="Insira aqui seu celular"
              />
            </div>
            <div className="col-sm-3">
              <TextField
                name="city"
                label="Cidade *"
                inputData={city}
                placeholder="Insira aqui a cidade"
              />
            </div>
            <div className="col-sm-3">
              <TextField
                name="uf"
                label="Estado *"
                inputData={uf}
                placeholder="Insira aqui o Estado"
              />
            </div>
            <div className="col-sm-3">
              <TextField
                name="zipcode"
                label="CEP *"
                inputData={zipcode}
                placeholder="Insira aqui o cep"
                mask={masks.zipcode}
              />
            </div>
            <input
              name="location"
              style={{ display: "none" }}
              value={location}
            />
          </div>
        </div>
      );
    }

    return null;
  }

  loadAreas = async () => {
    try {
      const { fetchAreas } = this.props;
      const { payload } = await fetchAreas();

      const areas = payload.data

      this.setState({ areas });
    } catch (err) {
      console.error('Load Areas', err);
    } finally {
      this.setState({ loadingAreas: false })
    }
  }

  loadServices = async () => {
    this.setState({ categoriesLoading: true });
    try {
      const categoriesResponse = await fetchCategoriesServices();
      this.setState({ categories: categoriesResponse });
    } catch (err) {
      console.error('Load Services', err);
    } finally {
      this.setState({ categoriesLoading: false });
    }
  };

  handleOnClickPolygon = (props, poly) => {
    const { selectedPolygons } = this.state;
    if (selectedPolygons.includes(poly._id)) {
      const color = poly.active ? '#00ff22' : '#ff0000';
      poly.setOptions({ fillColor: color });
      this.setState({
        selectedPolygons: this.state.selectedPolygons.filter(function (person) {
          return person !== poly._id
        })
      });

    } else {
      this.setState({
        selectedPolygons: [...this.state.selectedPolygons, poly._id]
      })
      poly.setOptions({ fillColor: '#ae00ff' });
    }
  }

  loadPolygons = async (selectedArea) => {
    try {
      const { selectedPolygons } = this.state;
      const { payload } = fetchPolygonsByAreaId(selectedArea);
      payload.then((result) => {
        const serverPolygons = result.data;
        const polygons = serverPolygons.map((poly) => {
          let polygonPoints;
          if (poly.location.coordinates) {
            polygonPoints = poly.location.coordinates[0].map(polygonLatLng => {
              return {
                lat: Number(polygonLatLng[1]),
                lng: Number(polygonLatLng[0])
              };
            });
          }
          if (poly.location.geometries) {
            const geometriesArray = poly.location.geometries.map(allGeometries => {
              return allGeometries.coordinates[0].map(polygonLatLng => {
                return {
                  lat: Number(polygonLatLng[1]),
                  lng: Number(polygonLatLng[0])
                };
              });
            });
            polygonPoints = geometriesArray.flat();
          }

          const polyColor = selectedPolygons.includes(poly._id) ? '#ae00ff' : (poly.active ? '#00ff22' : '#ff0000');
          return (
            <Polygon
              key={poly._id}
              coordinates={JSON.stringify(poly.location)}
              active={poly.active}
              rule={poly.rule}
              paths={polygonPoints}
              name={poly.name}
              _id={poly._id}
              serviceId={poly.serviceId}
              fillColor={polyColor}
              fillOpacity={0.55}
              strokeColor={"#2b2b2b"}
              strokeOpacity={0.8}
              strokeWeight={1}
              onClick={this.handleOnClickPolygon.bind(this)}
              onMouseover={() => this.setState({ polygonInfo: { show: true, name: poly.name } })} 
              onMouseout={() => this.setState({ polygonInfo: { show: false, name: "" } })} 
            >
              <h1>{poly.name}</h1>
            </Polygon>
          );
        });
        this.setState({ polygons });
      });
    } catch (err) {
      console.error('Load Polygons', err);
      Swal.fire({
        icon: "error",
        title: "Ops...",
        text: "Houve um erro ao buscar os polígonos.\nPor favor, tente novamente."
      });
    }
  };

  renderScheduling() {
    const { availability } = this.state;
    return (
      <div className="Form-formGroup">
        <div className="row">
          <div className="col-sm-12">
            <div className="Form-labelWrapper">
              <div className="Form-label">
                <span>Agenda Disponível</span>
              </div>
            </div>
            <ArtistScheduling
              availability={availability}
              onUpdateAvailability={this.handleShedules}
            />
          </div>
        </div>
      </div>
    )
  }

  renderCities() {
    const {
      cities,
      fields: { enabledCities },
    } = this.props;

    if (cities) {
      return (
        <div className="Form-formGroup">
          <div className="row">
            <div className="col-sm-12">
              <CheckboxGroup
                name="enabledCities"
                label="Cidades"
                list={cities}
                inputData={enabledCities}
              />
            </div>
          </div>
        </div>
      );
    }

    return null;
  }

  renderPolygons() {
    const {
      cities,
      areas,
      fields: { enabledCities },
    } = this.props;

    if (enabledCities.value !== 'undefined' && enabledCities.value.length > 0) {
      return (
        <div className="Form-formGroup">
          <div className="row">
            <div className="col-sm-12">
              <div className="Form-labelWrapper">
                <div className="Form-label">
                  <span>Polígonos</span>
                </div>
              </div>
            </div>
            <div className="col-sm-12">
              {
                cities.map((item) => {
                  if (enabledCities.value.includes(item.value)) {
                    const cityId = item.value;
                    return (
                      <Accordion
                        title={item.label}
                        key={cityId}
                        opened={false}
                        onClick={() => this.loadPolygons(cityId)}
                      >
                        <div style={{ height: 400 }}>
                          {
                            areas.map((item) => {
                              if (item._id === cityId) {
                                const { polygons } = this.state;
                                if (polygons.length > 0 && polygons) {
                                  return (
                                    <Map
                                      google={this.props.google}
                                      style={{ width: '100%', height: '400px' }}
                                      zoom={11}
                                      initialCenter={item.location}
                                    >
                                      {polygons.length > 0 && polygons || null}
                                    </Map>
                                  )
                                }
                              }
                            })
                          }
                        </div>
                      </Accordion>
                    )
                  }
                })
              }
            </div>
          </div>
        </div>
      );
    }

    return null;
  }

  renderServices() {
    const {
      fields: { enabledServices },
    } = this.props;
    const { categories } = this.state;
    const { artist, partner } = this.state;

    const mapCategories = (services, categoryName) => {
      return (
        <Accordion
          title={categoryName}
          key={`${categoryName}-${services[0].id}`}
          opened={!!(artist || partner)}
        >
          <CheckboxGroup
            name="enabledCities"
            list={services.map((service) => ({
              label: service.name,
              value: service.service_id,
            }))}
            inputData={enabledServices}
            noLabel={true}
          />
        </Accordion>
      )
    };

    if (categories) {
      return (
        <div className="Form-formGroup">
          <div className="row">
            <div className="col-sm-12">
              <div
                className={`Form-inputWrapper form-group ${this.servicesWrapperClass()}`}
              >
                <div className="Form-labelWrapper">
                  <div className="Form-label">
                    Serviços {this.renderServiceErrorMessage()}
                  </div>
                </div>
                <div>
                  {categories.map((categorie) => {
                    if (categorie.services.length > 0) return (
                      mapCategories(categorie.services, categorie.name)
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return null;
  }

  onDateTimeChange(e) {
    this.setState({ expiration: e });
  }

  onDateDisabledUntilChange(e) {
    this.setState({ disabledUntil: e });
  }

  getStatus(active, status) {
    if (active.value.toString() === "true") {
      status.value = "ativo";
      return status;
    }
    status.value = "desabilitado";
    return status;
  }

  renderDeactivationReason() {
    var {
      fields: { active, status, deactivationReason },
      params,
    } = this.props;

    const { isActive } = statusList.find(
      (statusList) => statusList.value === status.value
    );

    if (!params.artistId) return null;
    if (!status.value && active.value.toString() === "true") return null;
    if (isActive) return null;

    return (
      <div className="col-sm-4">
        <SelectField
          name="decativationReason"
          label="Motivos de desativação"
          listValues={optionsList || []}
          inputData={deactivationReason || ""}
        />
      </div>
    );
  }

  render() {
    var {
      fields: {
        name,
        takeRateValue,
        email,
        indicationCode,
        phoneNumber,
        cpf,
        rg,
        singuPayment,
        realtime,
        nacionality,
        naturality,
        dataexpedition,
        entityemission,
        fatherName,
        motherName,
        gender,
        birthdate,
        status,
        active,
        profilePicture,
        rgUpload,
        cpfUpload,
      },
      params,
      submitting,
    } = this.props;
    const { pageTitle, submitText, isAllocation, polygonInfo } = this.state;

    return (
      <form
        onSubmit={(evt) => this.onSubmit(evt)}
        className={`Form ${submitting ? "is-submitting" : ""}`}
      >
        <PageHeader title={pageTitle || "Adicionar profissional"} />

        <div className="Form-formGroup">
          <center>
            {params && params.artistId ? (
              <Link to={`artist/${params.artistId}`}>
                VISUALIZAR PROFISSIONAL
              </Link>
            ) : (
              ""
            )}
          </center>

          <br />
          <div className="row">
            <MouseFollower isActive={polygonInfo.show} label={polygonInfo.name} />
            <div className="col-sm-4">
              <TextField
                name="name"
                label="Nome *"
                inputData={name}
                placeholder="Insira aqui o nome completo"
              />
            </div>
            <div className="col-sm-3">
              <TextField
                name="email"
                label="Email *"
                inputData={email}
                placeholder="Insira um e-mail"
              />
            </div>
            <div className="col-sm-2">
              <TextField
                name="phone"
                label="Telefone *"
                mask={masks.phone}
                inputData={phoneNumber}
                placeholder="Insira aqui o telefone"
              />
            </div>
            <div className="col-sm-2">
              <TextField
                name="indicationCode"
                label="Código da indicadora"
                inputData={indicationCode}
                placeholder="Código da especialista indicadora"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-2">
              <SelectField
                name="status"
                label="Status *"
                listValues={statusList}
                inputData={
                  !status.value ? this.getStatus(active, status) : status
                }
              />
            </div>
            {this.renderDeactivationReason()}
            <div className="col-sm-2">
              <TextField
                name="birthdate"
                label="Data de Nascimento *"
                inputData={birthdate}
                placeholder="(dd/mm/aaaa)"
                mask={masks.date}
              />
            </div>
            <div className="col-sm-3">
              <RadioGroup
                name="gender"
                label="Sexo *"
                list={genders}
                inputData={gender}
              />
            </div>
          </div>
        </div>

        <div className="Form-formGroup">
          <div className="row">
            <div className="col-sm-12">
              <div className="Modal-sectionTitle">CONFIGURAÇÕES</div>
              <div className="col-sm-3">
                <RadioGroup
                  name="realtime"
                  label="Acesso ao Singu Agora?"
                  list={yesAndNo}
                  inputData={realtime}
                />
              </div>
              <div className="col-sm-3">
                <div className="Form-labelWrapper">
                  <div className="Form-label">
                    <span>Bloquear acesso até o dia</span>
                  </div>
                </div>
                <DateTime
                  name="disabledUntil"
                  locale="pt-br"
                  value={this.state.disabledUntil}
                  label="Desativada até"
                  isValidDate={this.validDate}
                  onChange={this.onDateDisabledUntilChange.bind(this)}
                  className="Form-input input-date"
                />
              </div>
              <div className="col-sm-3">
                <RadioGroup
                  name="singuPayment"
                  label="Adianta recebíveis?"
                  list={yesAndNo}
                  inputData={singuPayment}
                />
              </div>
            </div>
            <div className="col-sm-12">
              <div className="Modal-sectionTitle">
                PORCENTAGEM DE RECEBIMENTO
              </div>
              <div className="col-sm-3">
                <TextField
                  name="takeRateValue"
                  label="Porcentagem que a SINGU deve receber"
                  placeholder="Ex: 0"
                  inputData={takeRateValue}
                  percentage={true}
                  disabled={false}
                />
              </div>
              <div className="col-sm-2">
                <div className="Form-labelWrapper">
                  <div className="Form-label">
                    <span>Data de expiração do valor</span>
                  </div>
                </div>
                <DateTime
                  name="expiration"
                  value={this.state.expiration}
                  label="Data de expiração"
                  isValidDate={this.validDate}
                  onChange={this.onDateTimeChange.bind(this)}
                  className="Form-input input-date"
                />
              </div>
            </div>
          </div>
        </div>

        <br />

        <div
          className="Modal-section clearfix"
          style={{ display: this.state.editing ? "block" : "none" }}
        >
          <div className="Modal-sectionTitle">COMENTÁRIOS</div>
          <CommentComponent
            scheduleId={this.state.partnerId}
            comments={this.state.comments}
            notify={(msg, lvl) => this.notify(msg, lvl)}
            updateCallback={addCommentInArtist}
          />
          <center>
            {params && params.artistId ? (
              <Link to={`artist/${params.artistId}`}>
                VISUALIZAZR COMENTÁRIOS
              </Link>
            ) : (
              ""
            )}
          </center>
        </div>

        <div
          className="Form-formGroup"
          style={{ display: this.state.editing ? "block" : "none" }}
        >
          <div className="row">
            <div className="col-sm-4">
              <UploadField
                name="profilePicture"
                label="Upload da imagem de perfil"
                inputData={profilePicture}
                uploadCallback={(file, onProgress) =>
                  this.uploadPictureCallback(file, onProgress)
                }
                disabled={cpf.invalid}
                circlePreview={true}
                type="image"
                convert="png"
                avatar={true}
                crop={true}
                cropWidth={300}
                cropHeight={300}
              />
            </div>
            <div className="col-sm-4">
              <UploadField
                name="rgUpload"
                label="Upload do RG"
                inputData={rgUpload}
                uploadCallback={(file, onProgress) =>
                  this.uploadRgCallback(file, onProgress)
                }
                disabled={cpf.invalid}
                type="image"
                convert="png"
              />
            </div>
            <div className="col-sm-4">
              <UploadField
                name="cpfUpload"
                label="Upload do CPF"
                inputData={cpfUpload}
                uploadCallback={(file, onProgress) =>
                  this.uploadCpfCallback(file, onProgress)
                }
                disabled={cpf.invalid}
                type="image"
                convert="png"
              />
            </div>
          </div>
        </div>

        <div className="Form-formGroup">
          <div className="row">
            <div className="col-sm-6">
              <TextField
                name="fatherName"
                label="Nome do pai *"
                inputData={fatherName}
                placeholder="Nome do pai"
              />
            </div>
            <div className="col-sm-6">
              <TextField
                name="motherName"
                label="Nome da mãe *"
                inputData={motherName}
                placeholder="Nome da mãe"
              />
            </div>
          </div>
        </div>

        <div className="Form-formGroup">
          <div className="row">
            <div className="col-sm-6">
              <TextField
                name="cpf"
                label="CPF *"
                inputData={cpf}
                placeholder="Insira o CPF"
                mask={masks.cpf}
              />
            </div>
            <div className="col-sm-6">
              <TextField
                name="rg"
                label="RG *"
                inputData={rg}
                placeholder="Insira o RG"
              />
            </div>
            <div className="col-sm-6">
              <TextField
                name="dataexpedition"
                label="Data de expedição RG *"
                inputData={dataexpedition}
                placeholder="Data de expedição RG"
                mask={masks.date}
              />
            </div>
            <div className="col-sm-6">
              <TextField
                name="entityemission"
                label="Orgão Emissor RG *"
                inputData={entityemission}
                placeholder="Orgão Emissor RG"
              />
            </div>
          </div>
        </div>

        <div className="Form-formGroup">
          <div className="row">
            <div className="col-sm-6">
              <TextField
                name="nacionalidade"
                label="Nacionalidade *"
                inputData={nacionality}
                placeholder="Nacionalidade"
              />
            </div>
            <div className="col-sm-6">
              <TextField
                name="naturality"
                label="Naturalidade *"
                inputData={naturality}
                placeholder="Naturalidade"
              />
            </div>
          </div>
        </div>

        <NotificationSystem ref="notificationSystem" />
        {/** COPIAR O CONTEUDO DESSA TELA */}
        <div className="Form-formGroup">
          <div className="row">
            <div className="col-sm-12">
              <div className="Form-labelWrapper">
                <div className="Form-label">
                  <span>Novo Endereço</span>
                </div>
              </div>
              <Autocomplete
                apiKey={"AIzaSyBdlcgxa3ueG4y5U7qbPUVbcnndWL3Oabc"}
                placeholder="Insira aqui seu endereço"
                className="Form-input"
                style={{ width: "100%" }}
                onPlaceSelected={(addr) => this.populateAddressInfo(addr)}
                types={[]}
                componentRestrictions={{ country: "br" }}
              />
            </div>
          </div>
          {this.renderAddressFields()}
        </div>

        <div className="Form-formGroup">
          <div className="row">
            <div className="col-sm-6">
              <RadioGroup
                name="isAllocation"
                label="Ativar novo fluxo de alocação? *"
                list={yesAndNo}
                inputData={{
                  value: isAllocation,
                  onChange: (isAllocation) => this.setState({ isAllocation })
                }}
              />
            </div>
          </div>
        </div>

        {params && params.artistId && this.renderScheduling()}

        {this.renderCities()}

        {this.renderPolygons()}

        {this.renderServices()}

        <div className="Form-submitWrapper">
          <div className="row">
            <div className="col-sm-12">
              <button
                className="Form-submit"
                type="submit"
                disabled={submitting}
              >
                <span>{submitText || "CADASTRAR PROFISSIONAL"}</span>
                <span className="ss-users ss-icon" />
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    polygons: state.all
  };
}

export default connect(mapStateToProps, {
  fetchAreas,
  fetchPolygonsByAreaId
})(
  GoogleApiWrapper({
    apiKey: "AIzaSyBT3vNYVkejeaHoro4DikXez6z7GACBBak",
  })(ArtistForm)
);
