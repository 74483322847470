import React, { Component } from 'react';
import EditMGMComponent from '../../components/MGM/Edit';
import { getMGM, getUrlSigned, saveMGM } from '../../actions/areas/mgm';
import Loading from '../../components/Global/Loading/Loading';
import Swal from 'sweetalert2';
import config from '../../../config.json';

const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKOFICCE_V2 = config.backofficeV2[env];

export default class EditMGM extends Component {
  state = {
    mgm: {},
    loadingMGM: 'LOADING',
    savingMGM: 'READY',
  };

  componentDidMount() {
    window.location.href = `${BACKOFICCE_V2}/mgm/edit`;
  }

  saveMGM = () => {
    this.setState({ savingMGM: 'LOADING' });
    saveMGM(this.state.mgm)
      .then(() => {
        Swal.fire('Sucesso', 'MGM Alterado com sucesso', 'success');
      })
      .catch(err => {
        let message = 'Houve um erro ao atualizar o MGM';
        if (err && err.data && err.data.error && error.data.error.description)
          message = error.data.error.description;
        Swal.fire('Ops..', message, 'error');
      })
      .finally(() => {
        this.setState({ savingMGM: 'READY' });
      });
  };

  changeValue = evt => {
    const { mgm } = this.state;
    mgm[evt.target.name] = evt.target.value;
    this.setState({ mgm });
  };

  render() {
    if (this.state.loadingMGM === 'LOADING') {
      return <Loading />;
    }
    return (
      <EditMGMComponent
        changeValue={this.changeValue}
        saveMGM={this.saveMGM}
        mgm={this.state.mgm}
        savingMGM={this.state.savingMGM}
      />
    );
  }
}
