import "./style/main.css";
import "./style/GriddleTable.css";
import "./style/Modal.css";

import React from 'react';
import { render } from "react-dom";
import { Provider } from "react-redux";
import ReduxPromise from "redux-promise";
import { Router, Route, useRouterHistory, IndexRoute } from "react-router";
import { createHashHistory } from "history";
import { createStore, applyMiddleware } from "redux";
import { loggedIn } from "./models/auth.js";
import reducers from "./reducers";
import Navbar from "./components/Global/Navbar/Navbar";
import RestrictAccess from "./components/Global/RestrictAccess/RestrictAccess";
import Login from "./components/Global/Login";
import Logout from "./components/Global/Logout";
import Schedules from "./containers/Schedules/Schedules";
import RealTimeOrders from "./containers/RealTimeOrders/RealTimeOrders";
import LeadSchedulesConfigurationForm from "./containers/Leads/LeadSchedulesConfigurationForm";
import LeadSchedulesConfigurationList from "./containers/Leads/LeadSchedulesConfigurationList";
import UserInfo from "./containers/UserInfo/UserInfo";
import Users from "./containers/Users/Users";
import Partners from "./containers/Partners/Partners";
import Vouchers from "./containers/Vouchers/ListVouchers";
import NewVoucher from "./containers/Vouchers/NewVoucher";
import NewVoucherBatch from "./containers/Vouchers/NewVoucherBatch";
import EditVoucher from "./components/Vouchers/EditVoucher";
import Leads from "./containers/Leads/Leads";
import NewLead from './containers/Leads/NewLead';
import DynamicPrice from "./containers/DynamicPrice/PolygonMap";
import PolygonMap from "./containers/DynamicPrice/DynamicPrice";
import Package from "./containers/Packages/Packages";
import PackagePurchases from "./containers/Packages/PackagePurchases";
import DynamicPricesEdit from "./containers/AdministrativeZone/DynamicPrices";
import AdministrativeZone from "./containers/AdministrativeZone/AdministrativeZone";
import NewArtist from "./containers/Artists/NewArtist";
import EditArtist from "./containers/Artists/EditArtist";
import SendArtistPush from "./containers/Artists/SendPush";
import SendClientPush from "./containers/Users/SendPush";
import Artists from "./containers/Artists/Artists";
import ImportBatch from "./containers/Artists/importBatch";
import Payments from "./containers/Artists/Payments";
import ArtistsInfo from "./containers/Artists/ArtistsInfo";
import Infractions from "./containers/Infractions/Infraction";
import MeetRoom from "./containers/MeetRoom/MeetRoom";
import ChangePassword from "./containers/Staff/ChangePassword";
import RealtimeDynamicPrices from "./containers/RaltimeDynamicPrices/RealtimeDynamicPrices";
import PolygonsMap from "./containers/Polygons/PolygonsMap";
import Campaigns from "./containers/Campaigns/Campaigns";
import NewCampaign from "./containers/Campaigns/NewCampaign";
import ListStaff from "./containers/Staff/List";
import Areas from "./containers/Areas/List";
import Service from "./containers/Services/List";
import ServiceForm from "./containers/Services/EditService";
import LeadsReport from "./containers/Leads/LeadsReport";
import LeadsCalendarContainer from './containers/Leads/LeadsCalendarContainer';
import GetConsultantIntegrationUrl from "./containers/Consultant/GetUrl";
import AreaForm from "./containers/Areas/AreaEdit";
import EditMGM from "./containers/MGM/Edit";
import NewInvestiment from "./containers/Investiments/NewInvestiment";
import Investiments from "./containers/Investiments/Investiments";
import ArtistPGM from "./containers/Artists/ArtistPGM";
import ArtistPGP from "./containers/Artists/ArtistPGP";
import Transfers from "./containers/Transfers/Transfers";
import Airport from "./containers/Airport/Airport";
import Categories from "./containers/Categories/Categories.jsx";
import { userIsAdmin } from "./models/auth.js";
// import config from '../config.json';

// vendors
import "react-selectize/themes/index.css";
import "react-datetime/css/react-datetime.css";
import "./vendor/vendors";

// const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
// const BACKOFICCE_V2 = config.backofficeV2[env];

const appHistory = useRouterHistory(createHashHistory)({ queryKey: false });
const createStoreWithMiddleware = applyMiddleware(ReduxPromise)(createStore);

function requireAuth(nextState, replace, requireProfileCheck = true) {
  if (!loggedIn()) {
    replace({
      pathname: "/login",
      state: { nextPathname: nextState.location.pathname },
    });
  } else if (requireProfileCheck) {
    requireProfile(nextState, replace);
  }
}

function requireProfile(nextState, replace) {

  if (!userIsAdmin()) {
    const path = nextState.location.pathname;
    const permissions = JSON.parse(localStorage.permissions);

    const hasPermission = checkPermission(permissions, path);

    if (!hasPermission) {
      replace({
        pathname: "/restrict-access",
        state: { nextPathname: path },
      });
    }
  }

}

function checkPermission(permissions, path) {

  return permissions.some(permission => {
    path = path.startsWith('/') ? path.substring(1) : path;
    const permissionPath = permission.router.startsWith('/') ? permission.router.substring(1) : permission.router;

    if (permissionPath.includes('/:')) {
      const pathSections = path.split('/');
      const permissionSections = permissionPath.split('/');

      if (pathSections.length === permissionSections.length) {
        for (let i = 0; i < pathSections.length; i++) {
          if (
            pathSections[i] !== permissionSections[i] &&
            !permissionSections[i].startsWith(':')
          ) {
            return false;
          }
        }
        return true;
      }
    } else {
      return permissionPath.includes(path);
    }
    return false;
  });
}

const store = createStoreWithMiddleware(
  reducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default render(
  <Provider store={store}>
    <Router history={appHistory}>
      <Route path="/" exact component={Navbar} onEnter={(nextState, replace) => requireAuth(nextState, replace, false)}>
        <IndexRoute component={Schedules} />
        <Route path="schedules" component={Schedules} />
        <Route path="artist/import-batch" component={ImportBatch} onEnter={requireAuth} />
        <Route path="payments" component={Payments} onEnter={requireAuth} />
        <Route path="realtime-orders" component={RealTimeOrders} onEnter={requireAuth} />
        <Route path="users/:userId" component={UserInfo} onEnter={requireAuth} />
        <Route path="users" component={Users} onEnter={requireAuth} />
        <Route path="airport" component={Airport} onEnter={requireAuth} />
        <Route path="partners" component={Partners} onEnter={requireAuth} />
        <Route path="vouchers" component={Vouchers} onEnter={requireAuth} />
        <Route path="logout" component={Logout} onEnter={requireAuth} />
        <Route path="leads" component={Leads} onEnter={requireAuth} />
        <Route path="new-lead" component={NewLead} onEnter={requireAuth} />
        <Route path="leads/report" component={LeadsReport} onEnter={requireAuth} />
        <Route path="leads/:_id" component={NewLead} onEnter={requireAuth} />
        <Route path="leads-calendar" component={LeadsCalendarContainer} onEnter={requireAuth} />
        <Route path="new-artist" component={NewArtist} onEnter={requireAuth} />
        <Route path="edit-artist/:artistId" component={EditArtist} onEnter={requireAuth} />
        <Route path="infraction/artist/:artistId" component={Infractions} onEnter={requireAuth} />
        <Route path="infraction/artist/:artistId/:scheduleId" component={Infractions} onEnter={requireAuth} />
        <Route path="infraction/schedule/:scheduleId" component={Infractions} onEnter={requireAuth} />
        <Route path="send-push" component={SendArtistPush} onEnter={requireAuth} />
        <Route path="send-push-client" component={SendClientPush} onEnter={requireAuth} />
        <Route path="settings" component={DynamicPrice} onEnter={requireAuth} />
        <Route path="dynamic-price" component={DynamicPrice} onEnter={requireAuth} />
        <Route path="polygon-map" component={PolygonMap} onEnter={requireAuth} />
        <Route path="administrative-zone-new" component={DynamicPricesEdit} onEnter={requireAuth} />
        <Route path="administrative-zone" component={AdministrativeZone} onEnter={requireAuth} />
        <Route path="packages" component={Package} onEnter={requireAuth} />
        <Route path="packages/purchase" component={PackagePurchases} onEnter={requireAuth} />
        <Route path="new-voucher" component={NewVoucher} onEnter={requireAuth} />
        <Route path="voucher-batch" component={NewVoucherBatch} onEnter={requireAuth} />
        <Route path="edit-voucher/:voucherId" component={EditVoucher} onEnter={requireAuth} />
        <Route path="artists" component={Artists} onEnter={requireAuth} />
        <Route path="artist/pgm" component={ArtistPGM} onEnter={requireAuth} />
        <Route path="artist/pgp" component={ArtistPGP} onEnter={requireAuth} />
        <Route path="artist/:artistId" component={ArtistsInfo} onEnter={requireAuth} />
        <Route path="meeting-room" component={MeetRoom} onEnter={requireAuth} />
        <Route path="realtime-dynamic-prices" component={RealtimeDynamicPrices} onEnter={requireAuth} />
        <Route path="polygons" component={PolygonsMap} onEnter={requireAuth} />
        <Route path="campaigns" component={Campaigns} onEnter={requireAuth} />
        <Route path="campaigns/new" component={NewCampaign} onEnter={requireAuth} />
        <Route path="staff" component={ListStaff} onEnter={requireAuth} />
        <Route path="areas" component={Areas} onEnter={requireAuth} />
        <Route path="services" component={Service} onEnter={requireAuth} />
        <Route path="services/edit/:serviceId" component={ServiceForm} onEnter={requireAuth} />
        <Route path="service-category" component={Categories} onEnter={requireAuth} />
        <Route path="natura-integration" component={GetConsultantIntegrationUrl} onEnter={requireAuth} />
        <Route path="leads/evaluation/schedules/config/list" component={LeadSchedulesConfigurationList} onEnter={requireAuth} />
        <Route path="leads/evaluation/schedules/config/new" component={LeadSchedulesConfigurationForm} onEnter={requireAuth} />
        <Route path="leads/evaluation/schedules/config/edit/:scheduleDateId" component={LeadSchedulesConfigurationForm} onEnter={requireAuth} />
        <Route path="area/edit/:areaId" component={AreaForm} onEnter={requireAuth} />
        <Route path="mgm/edit" component={EditMGM} onEnter={requireAuth} />
        <Route path="new-investiment" component={NewInvestiment} onEnter={requireAuth} />
        <Route path="investiments" component={Investiments} onEnter={requireAuth} />
        <Route path="transfers" component={Transfers} onEnter={requireAuth} />
        <Route path="restrict-access" component={RestrictAccess} />
      </Route>
      <Route path="/login" component={Login} />
      <Route path="/change-password" component={ChangePassword} />
    </Router>
  </Provider>,
  document.getElementById("app")
);
