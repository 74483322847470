import React from 'react';

import { Component } from 'react';
import { Row } from 'react-bootstrap';
import PageHeader from '../../components/Global/PageHeader';
import {
  reprocessBonus,
  reprocessTransfers,
} from './../../actions/transfers/reprocess_transfers';

class Payments extends Component {
  state = {
    current: 0,
    future: 0,
    unavailable: 0,
    loading: false,
    loadingBonus: false,
    loadingFourteenDays: false,
  };

  reprocessArtistTransfers = fourteenDays => {
    if (fourteenDays) {
      this.setState({ loadingFourteenDays: true });
    } else {
      this.setState({ loading: true });
    }

    reprocessTransfers(fourteenDays)
      .then(() => {
        alert('Reprocessando pagamentos');
      })
      .catch(error => {
        console.log(error);
        alert(
          error?.response?.data?.description ||
            'Erro ao solicitar o reprocessamento dos pagamentos'
        );
      })
      .finally(() => {
        this.setState({ loading: false });
        this.setState({ loadingFourteenDays: false });
      });
  };

  reprocessArtistsBonus = () => {
    this.setState({ loadingBonus: true });
    reprocessBonus()
      .then(() => {
        alert('Reprocessando pagamentos');
      })
      .catch(error => {
        console.log(error);
        alert(
          error?.response?.data?.description ||
            'Erro ao solicitar o reprocessamento dos pagamentos'
        );
      })
      .finally(() => {
        this.setState({ loadingBonus: false });
      });
  };

  render() {
    const { loading, loadingBonus, loadingFourteenDays } = this.state;

    const isLoading = loading || loadingBonus || loadingFourteenDays;

    return (
      <div>
        <PageHeader title="Pagamentos" />

        <Row>
          <div className="col-xs-12">
            <b> Saldo disponível: </b> R$ {this.state.current}
            <b> ---- Saldo a receber: </b> R$ {this.state.future}
            <b> ---- Saldo bloqueado: </b> R$ {this.state.unavailable}
          </div>
        </Row>
        <br />
        <Row>
          <button
            className="btn btn-info"
            onClick={() => this.reprocessArtistTransfers()}
            disabled={isLoading}
          >
            {loading
              ? 'Reprocessando...'
              : 'Reprocessar pagamentos de adiantamento de recebiveis'}
          </button>
        </Row>
        <br />
        <Row>
          <button
            className="btn btn-info"
            onClick={() => this.reprocessArtistTransfers(true)}
            disabled={isLoading}
          >
            {loadingFourteenDays
              ? 'Reprocessando...'
              : 'Reprocessar pagamentos de 14 dias'}
          </button>
        </Row>
        <br />
        <Row>
          <button
            className="btn btn-info"
            onClick={this.reprocessArtistsBonus}
            disabled={isLoading}
          >
            {loadingBonus ? 'Reprocessando...' : 'Reprocessar bonus'}
          </button>
        </Row>
      </div>
    );
  }
}

export default Payments;

