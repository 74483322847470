import React, { Component } from 'react';
import './Paginate.css';

export default class Paginate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: props.currentPage,
      totalPages: props.totalPages,
    };
  }

  render() {
    const { totalPages, currentPage } = this.state;

    return (
      <div className="paginate-container">
        <button
          onClick={() => this.props.onChangePage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Anterior
        </button>
        <div className="paginate-select">
          <select
            value={currentPage}
            onChange={e => this.props.onChangePage(e.target.value)}
          >
            {Array.from({ length: totalPages }, (v, k) => k + 1).map(page => (
              <option
                key={page}
                value={page}
              >
                {page}
              </option>
            ))}
          </select>
          <div className="total-pages">{`/ ${totalPages}`}</div>
        </div>
        <button
          onClick={() => this.props.onChangePage(currentPage + 1)}
          disabled={totalPages === currentPage}
        >
          Próxima
        </button>
      </div>
    );
  }
}

