/* eslint-disable no-unused-vars */
/* global BACKEND_URL */
/* global _ */
/* global axios */
/* global $ */

import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import PageHeader from './../../components/Global/PageHeader';
import SchedulesGriddle from './../../components/Schedules/SchedulesGriddle';

// actions
import { fetchSchedules } from './../../actions/schedules/fetch_schedules';
import { fetchAreas } from './../../actions/areas/fetch_areas';
import { getCancelReasons } from './../../actions/schedules/cancel_schedule';
import { updateUser } from './../../actions/user_info/update_user';
import { updateScheduleAddress } from './../../actions/schedules/update_schedule_address';
import { updateSchedulePartner } from './../../actions/schedules/update_schedule_partner';
import { updateScheduleDate } from './../../actions/schedules/update_schedule_date';
import { updateScheduleTakeRate } from './../../actions/schedules/update_schedule_take_rate';
import { addCommentInSchedule } from '../../actions/schedules/add_comment_schedule';
import { updateScheduleService } from './../../actions/schedules/update_schedule_service';
import { updateScheduleVoucher } from './../../actions/schedules/update_schedule_voucher';
import { cancelSchedule } from './../../actions/schedules/cancel_schedule';
import { removeSchedulePartner } from './../../actions/schedules/remove_schedule_partner';
import { rechargeSchedule } from './../../actions/schedules/recharge_schedule.js';
import config from '../../../config.json';

const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKOFICCE_V2 = config.backofficeV2[env];

class Schedules extends Component {
  constructor(props) {
    super(props);

    this.state = { loading: true, currentPage: 1, cancelReasons: [] };
  }

  componentDidMount() {
    window.location.href = `${BACKOFICCE_V2}/schedules`;
  }

  componentWillMount() {
    this.loadData().then(() => this.setLoading(false));
  }

  componentWillReceiveProps(newProps) {
    const { schedules, areas, vouchers } = newProps;

    if (schedules) {
      this.setState({ schedules });
    }

    if (areas) {
      this.setState({ areas });
    }

    if (vouchers) {
      this.setState({ vouchers });
    }
  }

  loadData() {
    var d1 = new Date();
    d1.setDate(d1.getDate() - 5);

    var d2 = new Date();
    d2.setDate(d2.getDate() + 5);

    const promises = [
      this.getAllSchedules(1, { minDate: d1.toISOString().substr(0, 10), maxDate: d2.toISOString().substr(0, 10) }),
      this.getAllAreas(),
      this.getAllCancelReasons()
    ];

    return Promise.all(promises);
  }

  setLoading = loading => this.setState({ loading });

  getAllSchedules = (page, { orderBy, orderType, filter, minDate, maxDate, status, area, shortCode, onlyPreference }) => {
    this.setLoading(true);
    return this.props.fetchSchedules(page, orderBy, orderType, filter, minDate, maxDate, status, area, shortCode, onlyPreference)
      .then(() => this.setState({ loading: false, currentPage: page }));
  };

  getAllAreas() {
    return this.props.fetchAreas();
  }

  getAllCancelReasons() {
    return this.props.getCancelReasons().then((response) => {
      this.setState({ cancelReasons: response.payload.data });
    });
  }

  renderSchedulesGriddle() {
    const { schedules, areas, vouchers, cancelReasons } = this.state;
    const props = {
      schedules,
      areas,
      vouchers,
      cancelReasons,
      dataConfirmationAction: this.props.updateUser,
      updateAddressAction: this.props.updateScheduleAddress,
      updatePartnerAction: this.props.updateSchedulePartner,
      removePartnerAction: this.props.removeSchedulePartner,
      updateDateAction: this.props.updateScheduleDate,
      updateTakeRateAction: this.props.updateScheduleTakeRate,
      addCommentAction: this.props.addCommentInSchedule,
      updateServiceAction: this.props.updateScheduleService,
      updateVoucherAction: this.props.updateScheduleVoucher,
      cancelScheduleAction: this.props.cancelSchedule,
      rechargeScheduleAction: this.props.rechargeSchedule
    };

    return (
      <SchedulesGriddle
        {...props}
        getSchedules={this.getAllSchedules}
        currentPage={this.state.currentPage}
        numberOfPages={this.props.numberOfPages}
        loading={this.state.loading}
      />
    );
  }

  render() {
    return (
      <div>
        <PageHeader title="Agendamentos" />
        {this.renderSchedulesGriddle()}
      </div>
    );
  }
}

Schedules.propTypes = {
  schedules: PropTypes.array,
  vouchers: PropTypes.array,
  areas: PropTypes.array,
  fetchSchedules: PropTypes.func,
  fetchAreas: PropTypes.func,
  getCancelReasons: PropTypes.func,
  updateScheduleAddress: PropTypes.func,
  updateSchedulePartner: PropTypes.func,
  removeSchedulePartner: PropTypes.func,
  updateScheduleDate: PropTypes.func,
  updateScheduleTakeRate: PropTypes.func,
  addCommentInSchedule: PropTypes.func,
  updateScheduleService: PropTypes.func,
  updateScheduleVoucher: PropTypes.func,
  cancelSchedule: PropTypes.func,
  rechargeSchedule: PropTypes.func
};

function mapStateToProps(state) {
  return {
    schedules: state.schedules.all,
    numberOfPages: state.schedules.numberOfPages,
    vouchers: state.vouchers.all,
    areas: state.areas.all
  };
}

const mapActionsToProps = {
  fetchSchedules,
  fetchAreas,
  getCancelReasons,
  updateUser,
  updateScheduleAddress,
  updateSchedulePartner,
  removeSchedulePartner,
  updateScheduleDate,
  updateScheduleTakeRate,
  addCommentInSchedule,
  updateScheduleService,
  updateScheduleVoucher,
  cancelSchedule,
  rechargeSchedule
};

export default connect(mapStateToProps, mapActionsToProps)(Schedules);
