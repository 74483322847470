import { backendAPI } from '../../helper/apis';

export const FETCH_SERVICE = 'FETCH_SERVICE';

export function fetchService() {
  return backendAPI.get(`/services`);
}

export async function fetchCategoriesServices() {
  try {
    const response = await backendAPI.get(`/categories-services`);
    const data = response.data.data;
    return data;
  } catch (error) {
    console.error(error);
    throw new Error(error.response.data.message);
  }
}

