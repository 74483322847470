'use strict';
import React, { Component } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { fetchPackages } from '../../actions/packages/fetch_packages.js';
import PageHeader from '../../components/Global/PageHeader';
import Loading from '../Global/Loading/Loading';
import Paginate from '../Paginate/Paginate';
import AddPackage from './AddPackage';
import EditPackage from './EditPackage';
import './Packages.css';
import { fetchCategoriesServices } from '../../actions/service/fetch_service.js';

export const ALL_CITIES = 'Todas';

export default class PackagesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      packageItem: {},
      showEditModal: false,
      showNewModal: false,
      areas: [],
      avaiableServices: [],
      selectedArea: { _id: ALL_CITIES },
      packageInfo: {},
      packageList: {
        data: [],
        page: 1,
        pages: 1,
        total: 0,
      },
      loadingPackages: false,
      page: 1,
      categories: [],
    };
  }

  componentWillReceiveProps(newProps) {
    const { areas } = newProps;
    this.setState({ areas });
  }

  componentDidMount() {
    this.getPackages(1);
    this.getServices();
  }

  async getPackages(page, selectedAreaId) {
    this.setState({ loadingPackages: true });

    fetchPackages(page, selectedAreaId)
      .then(packageList => {
        this.setState({
          packageList: packageList,
        });
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Ocorreu um erro ao buscar os pacotes',
        });
        this.setState({
          packageList: {
            data: [],
            page: 1,
            pages: 1,
            total: 0,
          },
        });
        console.error(error);
      })
      .finally(() => {
        this.setState({ loadingPackages: false });
      });
  }

  async getServices() {
    fetchCategoriesServices().then(categories => {
      this.setState({
        categories,
      });
    });
  }

  handleClose() {
    this.setState({
      showEditModal: false,
      showNewModal: false,
    });
  }

  newPackage() {
    const { packageItemStructure } = this.state;
    this.setState({ packageItem: packageItemStructure, showNewModal: true });
  }

  editPackage = pack => {
    this.setState({
      packageItem: { ...pack },
      showEditModal: true,
    });
  };

  deletePackage(packId) {
    Swal.fire({
      title: 'Você realmente deseja excluir este pacote?',
      text: 'Essa ação não pode ser desfeita!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      confirmButtonColor: '#d33',
    }).then(result => {
      if (result.isConfirmed) {
        this.props.deletePackage(packId).then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Sucesso',
            text: 'Pacote excluído com sucesso.',
          });
          this.reloadPackages();
        });
      }
    });
  }

  reloadPackages() {
    this.getPackages(this.state.packageList.page, this.state.selectedArea._id);
  }

  handleAreaChoice(selectedArea) {
    this.setState({ selectedArea });
    this.getPackages(1, selectedArea._id);
  }

  onChangePage(page) {
    this.setState({ page });
    this.getPackages(page, this.state.selectedArea._id);
  }

  renderPackages() {
    const { data } = this.state.packageList;

    const renderedPacks = data.map((pack, index) => {
      return (
        <div
          className="col-md-4 col-xs-12"
          id={`pack-${pack._id}`}
          key={`${index}p`}
        >
          <div className="panel panel-default">
            <div className="panel-heading">
              <button
                value={index}
                className={'btn-edit-pack'}
                onClick={() => this.editPackage(pack)}
              >
                [EDITAR]
              </button>
              <button
                className={'btn-edit-pack'}
                onClick={this.deletePackage.bind(this, pack._id)}
              >
                [EXCLUIR]
              </button>
            </div>
            <div className="panel-body">
              <h3 className={'text-center pack-title'}>
                {pack.title['pt-BR']}{' '}
              </h3>
              <Col
                xs={8}
                style={{ padding: '20px 0' }}
              >
                <img
                  src={pack.mobileImage}
                  alt="Imagem do pacote"
                  className="img-responsive"
                />
              </Col>
              <div className="clearfix" />
              <Col xs={12}>
                <p>
                  <b>Valor Total do Pacote: </b> R${' '}
                  {pack.originalAmount.toFixed(2)}
                </p>

                <p>
                  <b>Desconto em Porcentagem: </b>
                  {pack.services.length > 0
                    ? parseFloat(pack.discountInPercent).toFixed(2)
                    : (0).toFixed(2)}
                  %
                </p>
                <p>
                  <b>Valor a ser mostrado no App: </b>
                  R${' '}
                  {pack.services.length > 0
                    ? pack.totalAmount.toFixed(2)
                    : (0).toFixed(2)}
                </p>
                <p>
                  <b>Quantidade de Parcelas: </b>
                  {pack.installmentMaxLimit ? pack.installmentMaxLimit : 1}
                </p>
                <p>
                  <b>Tempo para expirar:</b> {pack.expiresAfterDays} dias
                </p>
                <p>
                  <b>Subtítulo:</b> {pack.subtitle['pt-BR']}
                </p>
                {pack.services.length <= 0 ? (
                  <div> </div>
                ) : (
                  <div className="col-xs-12">
                    <b>Serviços: </b>
                    <ul>
                      {pack.services.map((service, index) => {
                        return (
                          <li key={`${service.serviceList[0]}-${index}`}>
                            <p>
                              <b>Serviço: </b>{' '}
                              {service.title['pt-BR']
                                ? service.title['pt-BR']
                                : service.title}{' '}
                              ({service.serviceList[0]})
                            </p>
                            <p>
                              {pack.valueType === 'P' ? (
                                <span>
                                  <b>Valor:</b> %{service.value}{' '}
                                  <b>Quantidade:</b>{' '}
                                </span>
                              ) : (
                                <span>
                                  <b>Valor:</b> R${' '}
                                  {parseFloat(service.value).toFixed(2)}{' '}
                                  <b>Quantidade:</b>{' '}
                                </span>
                              )}

                              {service.count}
                            </p>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </Col>
            </div>
          </div>
        </div>
      );
    });
    return (
      <div
        className="col-xs-12"
        style={{ marginTop: '20px' }}
      >
        {renderedPacks}
      </div>
    );
  }

  renderHeader() {
    const { areas } = this.state;
    const activeAreas = areas.filter(area => area.active);

    if (!areas && areas.length > 0) {
      return <Loading />;
    }

    return (
      <div className="row">
        <div className="title-container">
          <h3 className={'text-center'}>
            Escolha a cidade para gerenciar/visualizar os pacotes:{' '}
          </h3>
          <button
            onClick={() => {
              this.setState({ showNewModal: true });
            }}
            className="btn btn-default"
            type="button"
          >
            Novo Pacote &nbsp; <i className="glyphicon glyphicon-plus" />
          </button>
        </div>
        {activeAreas && activeAreas.length > 0 && (
          <div className="col-xs-12 text-center">
            <button
              key={ALL_CITIES}
              id={ALL_CITIES}
              className={`btn btn-pack btn-default btn-area ${
                this.state.selectedArea &&
                this.state.selectedArea._id === ALL_CITIES
                  ? 'active'
                  : ''
              }`}
              onClick={this.handleAreaChoice.bind(this, { _id: ALL_CITIES })}
            >
              Todas as cidades
            </button>
            {activeAreas.map(area => {
              return (
                <button
                  key={area._id}
                  id={area._id}
                  className={`btn btn-pack btn-default btn-area ${
                    this.state.selectedArea &&
                    this.state.selectedArea._id === area._id
                      ? 'active'
                      : ''
                  }`}
                  onClick={this.handleAreaChoice.bind(this, area)}
                >
                  {area.city} - {area.stateShort}
                </button>
              );
            })}
          </div>
        )}
      </div>
    );
  }

  renderPagination() {
    const { packageList, loadingPackages } = this.state;

    if (loadingPackages) {
      return null;
    }

    return (
      <Paginate
        currentPage={packageList.page}
        totalPages={packageList.pages}
        onChangePage={this.onChangePage.bind(this)}
      />
    );
  }

  render() {
    const {
      showEditModal,
      packageItem,
      showNewModal,
      categories,
      areas,
      loadingPackages,
      packageList,
    } = this.state;

    const { addPackage, editPackage } = this.props;

    return (
      <Col xs={12}>
        <Row>
          <PageHeader title={'Pacotes'} />
          {this.renderHeader()}
          {loadingPackages ? (
            <Loading />
          ) : (
            Object.keys(packageList.data).length > 0 && this.renderPackages()
          )}
          {this.renderPagination()}
        </Row>
        <Modal
          show={showEditModal}
          onHide={this.handleClose.bind(this)}
          className="Modal Modal--large"
        >
          <Modal.Header closeButton>
            <Modal.Title>Editar Pacote</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <EditPackage
              editPackage={editPackage}
              showEditModal={showEditModal}
              pack={packageItem}
              handleClose={this.handleClose.bind(this)}
              reload={this.reloadPackages.bind(this)}
              categories={[...categories]}
            />
          </Modal.Body>
        </Modal>
        <Modal
          show={showNewModal}
          onHide={this.handleClose.bind(this)}
          className="Modal Modal--large"
        >
          <Modal.Header closeButton>
            <Modal.Title>Novo Pacote</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddPackage
              addPackage={addPackage}
              showNewModal={showNewModal}
              pack={packageItem}
              areas={areas}
              categories={categories}
              handleClose={this.handleClose.bind(this)}
              reload={this.reloadPackages.bind(this)}
            />
          </Modal.Body>
        </Modal>
      </Col>
    );
  }
}

