import React from 'react';

class MouseFollower extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      x: 0,
      y: 0,
    };
  }

  componentDidMount() {
    window.addEventListener('mousemove', this.handleMouseMove);
  }

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.handleMouseMove);
  }

  handleMouseMove = e => {
    this.setState({
      x: e.clientX,
      y: e.clientY,
    });
  };

  render() {
    const { x, y } = this.state;
    const { isActive, label } = this.props;

    if (!isActive || !label) {
      return null;
    }

    return (
      <div
        style={{
          position: 'fixed',
          top: y - 30,
          left: x - 40,
          background: 'black',
          color: 'white',
          padding: '4px 8px',
          borderRadius: '5px',
          fontSize: '14px',
          pointerEvents: 'none',
          zIndex: 9999,
          textDecoration: 'capitalize',
        }}
      >
        {label
          .toLowerCase()
          .replace(/_/g, ' ')
          .replace(/\b\w/g, l => l.toUpperCase())}
      </div>
    );
  }
}

export default MouseFollower;

