import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import PackageList from '../../components/Packages/PackagesList';
import { backendAPI } from '../../helper/apis';
import { fetchAreas } from './../../actions/areas/fetch_areas';

class Packages extends Component {
  componentWillMount() {
    this.getAreas();
  }

  getAreas() {
    return this.props.fetchAreas();
  }

  deletePackage(packId) {
    return backendAPI.delete(`/packages/${packId}`);
  }

  addPackage(areas, pack) {
    pack.areas = areas;
    return backendAPI.post(`/packages`, pack);
  }

  editPackage(pack) {
    pack.areas = pack.areas.map(area => area._id);
    return backendAPI.put(`/packages/${pack._id}`, pack);
  }

  render() {
    return (
      <PackageList
        areas={this.props.areas}
        deletePackage={this.deletePackage}
        addPackage={this.addPackage}
        editPackage={this.editPackage}
      />
    );
  }
}

Packages.propTypes = {
  fetchAreas: PropTypes.func,
};

const mapActionsToProps = {
  fetchAreas,
};

function mapStateToProps(state) {
  return {
    areas: state.areas.all,
  };
}

export default connect(mapStateToProps, mapActionsToProps)(Packages);

