'use strict';

import React, { Component } from 'react';

import { Nav, NavItem } from 'react-bootstrap';
import PackageService from './PackageService';
import Swal from 'sweetalert2';

export default class AddPackage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeNavItem: 'Serviços',
      areas: props.areas,
      selectedAreas: [],
      pack: {},
      prepack: {},
      categories: props.categories,
      packageStructure: {
        title: '',
        subtitle: '',
        totalAmount: 0,
        discountAmount: 0,
        discountInPercent: 0,
        expiresAfterDays: 0,
        mobileImage: '',
        originalAmount: 0,
        serviceDescription: '',
        installmentMaxLimit: 1,
        services: [
          {
            count: 0,
            serviceList: [],
            title: '',
            value: 0,
          },
        ],
      },
    };
  }

  updateAreas = areas => {
    this.setState({ areas });
  };

  updateCategories = categories => {
    this.setState({ categories });
  };

  componentDidUpdate(prevProps) {
    if (this.props.areas !== prevProps.areas) {
      this.updateAreas(this.props.areas);
    }

    if (this.props.categories !== prevProps.categories) {
      this.updateCategories(this.props.categories);
    }
  }

  savePackage = evt => {
    const { pack, selectedAreas } = this.state;

    if (pack.serviceDescription.length > 255) {
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Descrição deve ter no máximo 255 caracteres.',
      });
      return;
    }
    evt.preventDefault();

    this.props
      .addPackage(
        selectedAreas,
        !pack.installmentMaxLimit ? { ...pack, installmentMaxLimit: 1 } : pack
      )
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: 'Pacote adicionado com sucesso.',
        });
        this.props.handleClose();
        this.props.reload();
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Não foi possível adicionar o pacote.',
        });
        console.log(err);
      });
  };

  onUpdatePack = pack => {
    this.setState({ pack });
  };

  handleFormEdit(evt) {
    const { value, name } = evt.target;
    const { pack } = this.state;
    pack[name] = value;
    this.setState({ pack });
  }

  handleSelectArea(evt) {
    this.setState({ selectedAreas: [evt.target.value] });
  }

  renderId() {
    if (this.state.package && this.state.package._id) {
      return (
        <input
          type={'hidden'}
          name={'_id'}
          value={this.state.package._id}
        />
      );
    }
  }

  handleNavItemSelection = key => {
    this.setState({ activeNavItem: String(key) });
  };

  renderAreas() {
    const { areas } = this.state;

    return (
      <select
        className="form-control"
        onChange={this.handleSelectArea.bind(this)}
      >
        <option
          disabled
          selected
          value
        >
          {' '}
          -- selecione uma cidade --{' '}
        </option>

        {areas.map(area => {
          if (area.active) return <option value={area._id}>{area.name['pt-BR']}</option>;
        })}
      </select>
    );
  }

  render() {
    const { prepack, pack, categories } = this.state;

    return (
      <div className="row">
        <form onSubmit={this.savePackage}>
          {this.renderId()}
          <div className="col-xs-6 form-group">
            <label>Título</label>
            <input
              className="form-control form-control-normal"
              name={'title'}
              tabIndex={1}
              required
              value={this.state.pack.title}
              onChange={this.handleFormEdit.bind(this)}
            />
          </div>
          <div className="col-xs-6 form-group">
            <label>Subtitulo</label>
            <input
              className="form-control form-control-normal"
              name={'subtitle'}
              tabIndex={2}
              required
              value={this.state.pack.subtitle}
              onChange={this.handleFormEdit.bind(this)}
            />
          </div>
          <div className="col-xs-12 form-group">
            <label>Url da Imagem</label>
            <input
              className="form-control form-control-normal"
              name={'mobileImage'}
              tabIndex={3}
              required
              value={this.state.pack.mobileImage}
              onChange={this.handleFormEdit.bind(this)}
            />
          </div>
          <div className="col-xs-12 form-group">
            <label>Descrição do Pacote</label>
            <input
              className="form-control form-control-normal"
              name={'serviceDescription'}
              tabIndex={3}
              max={255}
              required
              value={this.state.pack.serviceDescription}
              onChange={this.handleFormEdit.bind(this)}
            />
          </div>
          <div className="col-xs-4 form-group">
            <label>Dias para expirar</label>
            <input
              type="number"
              className="form-control form-control-normal"
              name={'expiresAfterDays'}
              tabIndex={4}
              required
              value={this.state.pack.expiresAfterDays}
              onChange={this.handleFormEdit.bind(this)}
            />
          </div>
          <div className="col-xs-4 form-group d-flex-col">
            <label>Cidade</label>
            {this.renderAreas()}
          </div>
          <div className="clearfix" />

          <hr />
          <div>
            <Nav
              bsStyle="tabs"
              activeKey={this.state.activeNavItem}
              onSelect={k => this.handleNavItemSelection(k)}
            >
              <NavItem
                eventKey="Serviços"
                href="/home"
              >
                Serviços
              </NavItem>
            </Nav>
            <div className="row">
              <PackageService
                pack={pack}
                prepack={prepack}
                categories={categories}
              />
            </div>
          </div>

          <div className="text-right">
            <button
              type={'button'}
              className="btn btn-pack btn-default"
              onClick={this.props.handleClose}
            >
              Fechar
            </button>
            <button
              className="btn btn-pack btn-success"
              type="submit"
            >
              Salvar
            </button>
          </div>
        </form>
      </div>
    );
  }
}

