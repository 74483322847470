import { ALL_CITIES } from '../../components/Packages/PackagesList';
import { backendAPI } from '../../helper/apis';

export async function fetchPackages(page, areaId) {
  let url = '';

  if (areaId && areaId !== ALL_CITIES) {
    url = `/packages/area/${areaId}`;
  } else {
    url = '/packages';
  }

  try {
    const response = await backendAPI.get(url, { params: { page } });

    const data = response.data.data;

    return {
      data: data.packages,
      page: Number(data.page),
      pages: data.pages,
      total: data.total,
    };
  } catch (error) {
    throw new Error(error.response.data.message);
  }
}

